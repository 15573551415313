<template>
    <div class="pos-rel container">
        <Header/>
        <Career/>
    </div>
</template>

<script>
    import Header from "@/components/layout/Header";
    import Career from "@/views/Career";

    export default {
        name: 'app',
        components: {
            Header,
            Career
        }
    }
</script>

<style lang="scss">
    @import "@/assets/scss/index.scss";
</style>
