<template>
    <div class="feature">
        <h3 class="h3">{{feature.title}}</h3>
        <p class="p1 text-muted">
            {{feature.text}}
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            feature: Object
        }
    }
</script>
