<template>
    <div class="logo">
        <a v-bind:href="homePage">
            <img v-bind:src="logo" alt="Brand Shop Logo"/>
        </a>
    </div>
</template>

<script>
    import logo from "@/assets/bs_logo.svg";

    export default {
        props: {},
        data() {
            return {
                logo,
                homePage: "https://brandshop.ru"
            }
        }
    }
</script>

<style lang="scss" scoped>
    .logo {
        width: 20px;
        height: 27px;
        margin: 10px 0;
    }

    @media only screen and (min-width: 48em) {
        .logo {
            width: 35px;
            height: 44px;
            margin: 34px 0;
        }
    }
</style>
