<template>
    <header class="row">
        <nav class="col-1 col-md-1 col-sm-1 col-sm-offset-0 col-offset-0 col-md-offset-0 col-xs-offset-0">
            <Logo/>
        </nav>
    </header>
</template>

<script>
    import Logo from "@/components/Logo";

    export default {
        components: {
            Logo
        }
    }
</script>
