<template>
    <a
        @click.prevent="$emit('click')"
    >{{value}}
    </a>
</template>

<script>
    export default {
        props: {
            value: String
        }
    }
</script>
