<template>
    <div class="career__box">
        <h3 class="h3 career__title" v-on:click="toggleExpanded">{{description.title}}
            <div class="expand-icon">
                <img
                    v-if="!expanded"
                    :src="plus_icon"
                    alt="Plus Icon"
                />
                <img
                    v-if="expanded"
                    :src="minus_icon"
                    alt="Minus Icon"
                />
            </div>
        </h3>
        <p class="career__subTitle">
            {{description.subTitle}}
        </p>
        <div class="career__text" v-if="expanded">
            <div class="mb-1">
                <p class="p1">{{description.content.responsibility}}</p>
                <p class="text-muted" v-for="resp in description.content.respList" v-bind:key="resp">&mdash; {{resp}}</p>
            </div>
            <div class="mb-1">
                <p class="p1">{{description.content.requirements}}</p>
                <p
                    class="text-muted"
                    v-for="(req, i) in description.content.reqList"
                    :key="i">&mdash; {{req}}
                </p>
            </div>
            <div class="mb-1">
                <p class="p1">{{description.content.offers}}</p>
                <p
                    class="text-muted"
                    v-for="(off, i) in description.content.offersList"
                    :key="i">&mdash; {{off}}
                </p>
            </div>
            <p class="p1">{{description.emailDescription}} <a class="text-muted" v-bind:href="'mailto:'+description.email">{{description.email}}</a></p>
            <p v-if="description.additionally"><strong>{{description.additionally}}</strong></p>
        </div>
    </div>
</template>

<script>
    import plus_icon from "@/assets/plus_icon.svg";
    import minus_icon from "@/assets/minus_icon.svg";

    export default {
        props: {
            description: Object
        },

        data() {
            return {
                expanded: false,
                plus_icon,
                minus_icon
            }
        },

        methods: {
            toggleExpanded() {
                this.expanded = !this.expanded;
            }
        }
    }
</script>
